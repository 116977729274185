// 工具模块
import config from "@/common/config"
import store from "@/store"
const tool = {
  setCookie(key,value,time=24){
    var date = new Date();
    date.setTime(date.getTime()+(time*60*60*1000));
    var expires = "expires="+date.toGMTString();
    document.cookie = `${config.prefix}${key}` + "=" + value + "; " + expires;
  },
  getCookie(key){
    var name = `${config.prefix}${key}` + "=";
    var params = document.cookie.split(';');
    for(var i=0; i<params.length; i++) 
    {
      var item = params[i].trim();
      if (item.indexOf(name)==0) return item.substring(name.length,item.length);
    }
    return "";
  },
  setLocalCookie(key,value,time=24){
    let data = {
      val:value,
      time:Date.now() + time * 60 * 60 * 1000
    }
    key = `${config.prefix}${key}`
    tool.setStorage(`${key}_cookie`,data)
    
  },
  getLocalCookie(key){
    key = `${config.prefix}${key}`
    let data = tool.getStorage(`${key}_cookie`) || {val:'',time:0}
    if(Date.now() < data.time){
      return data.val;
    }else{
      return '';
    }
  },
  setStorage(key,val,storage){
    key = `${config.prefix}${key}`
    storage = storage || localStorage
    let data = {
      value:val
    }
    storage.setItem(key,JSON.stringify(data));
  },
  getStorage(key,storage){
    key = `${config.prefix}${key}`
    storage = storage || localStorage
    let data = storage.getItem(key);
    if(!data) return data;
    data = JSON.parse(data);
    return data.value
  },
  getCompany(type,key){
    console.log( config.company[type][key],type,key)
    return config.company[type][key]
  },
  getURL(url,data={}){
    let index = 0;
    for(let key in data){
      let _symbol = index++ === 0 ? '?' : '&';
      url+=`${_symbol}${key}=${data[key]}`
    }
    return url;
  },
  // 获取当前日期
  getDate(time,format="yyyy-MM-dd"){
    let date = tool.getFormatTime(time);
    let dateItem = {
      yyyy:date.getFullYear(),
      MM:tool.getTimeText(date.getMonth() + 1),
      dd: tool.getTimeText(date.getDate()),
      hh: tool.getTimeText(date.getHours()),
      mm: tool.getTimeText(date.getMinutes()),
      ss: tool.getTimeText(date.getSeconds())
    };
    let text = format;
    for(let key in dateItem){
      text = text.replace(key,dateItem[key])
    }
    return text;
  },
  
  getTimeText(time){
    time = String(time)
    return time[1] ? time : `0${time}`
  },
  // 获取上周日期
  getPrepWeek(){
    let date = new Date();
    let day = date.getDay();
    let curTime = date.getTime();
    let prepstartDay = curTime - (day + 7 - 1 ) * 24 * 60 * 60 * 1000;
    let prepEndDay = curTime - day * 24 * 60 * 60 * 1000;
    return {
      start_time:tool.getDate(prepstartDay),
      end_time:tool.getDate(prepEndDay)
    }
  },
  // 获取上个月日期
  getPrepMonth(){
    let date = new Date();
    let day = date.getDate();
    let curTime = date.getTime();
    let prepEndDay = curTime - day * 24 * 60 * 60 * 1000;
    let preDate = new Date(prepEndDay);
    let preTime = preDate.getTime();
    let preYear = preDate.getFullYear();
    let preMonth = preDate.getMonth() + 1;
    let preCountDay = new Date(preYear,preMonth,0).getDate()
    let prepstartDay = preTime - (preCountDay - 1) * 24 * 60 * 60 * 1000;
    return {
      start_time:tool.getDate(prepstartDay),
      end_time:tool.getDate(prepEndDay)
    }
  },
  // 获取时间戳
  getFormatTime(time=""){
    if(typeof time == "object" && time != null) return time;
    if(typeof time == "string") time = time.replace(/-/g,"/");
    return time ? new Date(time) : new Date();
  },
  getTime(time){
    let value = 0;
    try{
      let dealWithTime = tool.getFormatTime(time)
      value = dealWithTime.getTime();
    }catch(err){ console.log(err)}
    return value
  },
  getTimePhase(time){
    time = time/1000;
    if(time < 60) return 0;
    if(time < 60 * 60) return 1;
    if(time < 60 * 60 * 24 ) return 2;
    if(time > 60 * 60 * 24) return 3;
  },
  getShowTime:(time,format="yyyy年MM月dd天hh小时mm分钟ss秒") => {
    let text = format;
    time = time || 0;
    time = Math.floor(time/1000)
    let dataList = {
      ss:time % 60,
      mm:Math.floor( time / 60 ) % 60,
      hh:Math.floor( time / ( 60 * 60 ) ) % 60,
      dd:Math.floor( time / ( 24 * 60 * 60 ) ) % 24,
      MM:0,
      yyyy:0
    }
    for(let key in dataList){
      if(!dataList[key] && !["mm","ss"].includes(key)){
        let reg = new RegExp(`${key}[\u4300-\u9fa5-\s:]{0,}`,"g")
        text = text.replace(reg,"")
      }else{
        let val = key == "yyyy" ? dataList[key] : tool.getTimeText(dataList[key])
        text = text.replace(key,val)
      }
    }
    return text;
  },
  getSystemInfo(){
    let app = navigator.appVersion;
    let leftIndex=app.indexOf('(');
    let rightIndex=app.indexOf(')');
    let text=app.substring(leftIndex+1,rightIndex); 
    text = text.split(";");
    var Mobile_Iphone=text[0];
    var Mobile_Android=text[2];
    var res=/Android/;
    var result=res.test(Mobile_Android);
    let data = {
      device:Mobile_Iphone,
      version:'',
      width:window.screen.width,
      height:window.screen.height,
    }
    switch(Mobile_Iphone){
      case 'Linux':
        let device = ''
        if(result){
          device = text[4]
          data.version = text[2]
        }else{
          device = Mobile_Android
          data.version = text[1]
        }
        data.device = device.split('Build')[0]
        break;
      case 'iPhone':;
      case 'iPad':
        data.device = text[0];
        let version = text[1].match(/OS\s([\d\_]+)\slike/g);
        version = version ? version[0] : '';
        data.version = version.split(/\s/)[1] ?  `ios ${version.split(/\s/)[1]}`: ''
        break;
    }
    data.device = String(data.device).trim()
    data.version = String(data.version).trim().replace(/_/g,'.')
    return data;
    
  },
  sortChinese(data,key){
    let dataList = []
    dataList = key ? data.sort((params1,params2)=>{
      return params1[key].localeCompare(params2[key],"zh")
    })
    :
    dataList = data.sort((params1,params2)=>{
      return params1.localeCompare(params2,"zh")
    })
    return dataList;
  },
  // type 0 为文本 1 为聊天
  getChatContent(content="",type=0,voice_id="",voice_length=0,index=0,localId="",phase=1){
    return {
      content:content,
      content_type:type,
      voice_id,
      voice_length,
      phase,
      voice_page:index+1,
      doctor_index:index,
      localId
    }
  },
  trimEnter(content){
    let text = content || ""
    text = text.replace(/[,\n]+/g,"\n")
    text = text.replace(/(^\n)|(\n$)/g,"")
    return text;
  },
  isLocal(){
    const localURL = /^(192\.168\.\d{1,3}\.\d{1,3})|(localhost)|(127.0.0.1)|(172\.16\.\d{1,3}\.\d{1,3})$/;
    let hostname = window.location.hostname;
    return localURL.test(hostname)
  },
  dealWithText(content,count=0){
    const enterSeparator = "xxxxxx";
    let isEnterEnd = /\n$/.test(content)
    content = content.replace(/\n/g,enterSeparator)
    let contentList = content.split(/[\s]/g)
    let resultList = contentList.map(val=>{
      let list = val.match(/([^\w]+)|(\w+)/g) || [];
      list = list.map(x=>{
        return /\w+/g.test(x) ? x : x.split("")
      })
      return list.flat()
    })
    resultList = resultList.flat();
    let result = "";
    let fieldReg = /[\u4e00-\u9fa5a-zA-Z0-9]+/
    let wordsCount = 0;
    // count = count || resultList.length;
    // count = count < resultList.length ? count : resultList.length
    for(let index in resultList){
      let val = resultList[index]
      let separator = /\w+/g.test(val) && !/^\d+$/g.test(val) && index !== 0 ? " ": ""
      result += `${separator}${val}`
      if(fieldReg.test(val)) wordsCount += 1;
      if(count != 0 && wordsCount >= count) break;
    }
    let enterReg = new RegExp(enterSeparator,"g")
    result = result.replace(enterReg,"\n")
    // if(isEnterEnd) result = `${result}`
    return {
      content:result,
      count:wordsCount
    }
  },
  getRoleName(){
    let roleName = store.state.common.projectInfo.role_name || "管理员";
    return roleName
  },
  getMeetStatus(data,status=0){
    let meetingStatus = config.categary.meeting;
    let auditStatus = config.categary.audit;
    if(data.is_test) return meetingStatus[0]
    
    // 如果状态为0，且审核状态为 审核未通过，则显示审核未通过
    if(data.audit_status === auditStatus.fail) return meetingStatus[4];
    // 如果 审核状态为 管理员审核通过 则显示系统审核中
    // if(data.audit_status == auditStatus.submitAudit) return meetingStatus[8]
    if(data.audit_status == auditStatus.submitAudit) return status ? "系统审核中" : "审核中"
    // 如果 审核状态为 重新录制 则显示 重新录制
    if(data.audit_status == auditStatus.rerecord) return meetingStatus[9]
    //如果支付状态等于2，返回支付失败
    if(data.pay_status == 2) return meetingStatus[7]
    // 如果会议状态 未结束，则直接显示
    if(data.status < 2) return meetingStatus[data.status];
    // 如果 审核状态为 审核中 则显示审核中
    // if(data.audit_status <= auditStatus.auditing) return meetingStatus[2]
    if(data.audit_status <= auditStatus.auditing) return status ? `${tool.getRoleName()}审核中` : "审核中"
    // 如果 没有签名，则 显示 审核通过
    if(!data.sign_name)  return status ? "待支付" : meetingStatus[3]
    // 如果 未支付则显示 未支付 已支付则显示已支付
    return data.pay_status ? meetingStatus[6] : meetingStatus[5]
  },
  getCityConfig:async function(){
    return new Promise((resolve)=>{
      import('@/common/citys.json').then(data=>{
        resolve(data.default)
      })
    })
  },
  blur(data,key){
    if(key) data[key] = false;
    console.log("执行了")
    window.scrollTo(0,0)
    setTimeout(()=>{
      window.scrollTo(0,0)
    },1000)
  },
  intToChinese(str) {
    str = str + "";
    var len = str.length - 1;
    var idxs = [
      "","十","百","千","万","十","百","千","亿","十","百","千","万","十","百","千","亿",
    ];
    var num = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
    return str.replace(/([1-9]|0+)/g, function ($, $1, idx,) {
      var pos = 0;
      if ($1[0] != "0") {
        pos = len - idx;
        if (idx == 0 && $1[0] == 1 && idxs[len - idx] == "十") {
          return idxs[len - idx];
        }
        return num[$1[0]] + idxs[len - idx];
      } else {
        var left = len - idx;
        var right = len - idx + $1.length;
        if (Math.floor(right / 4) - Math.floor(left / 4) > 0) {
          pos = left - (left % 4);
        }
        if (pos) {
          return idxs[pos] + num[$1[0]];
        } else if (idx + $1.length >= len) {
          return "";
        } else {
          return num[$1[0]];
        }
      }
    });
  },

  intToChineseNew(input) {
    const digitMap = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
    const chineseDigits = Array.from(input).map(char => {
      if (char === '-') {
        return '-';
      } else if (!isNaN(parseInt(char))) {
        return digitMap[parseInt(char)];
      } else {
        return char;
      }
    });

    return chineseDigits.join('');
  },
}





export default tool;