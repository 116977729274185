import { createRouter, createWebHashHistory } from "vue-router"
import tool from "@/common/tool"
import config from "@/common/config"
import weixin from "@/common/weixin"
import axios from "@/common/axios"
import urls from "@/common/urls"
import store from "@/store"
const routeType = {
  meeting: "meeting",
  common: "common",
  admin: "admin",
  doctor: "doctor",
  ignore: "ignore"
}
const adminRoutes = [
  {
    path: "/doctorInfo",
    categary: routeType.admin,
    name: "doctorInfo",
    component: () => import(/* webpackChunkName: "doctorInfo", webpackPrefetch: true */ "@/components/page/admin/doctorInfo.vue")
  },
  {
    path: "/doctorPreview",
    categary: routeType.admin,
    name: "doctorPreview",
    component: () => import(/* webpackChunkName: "doctorPreview", webpackPrefetch: true */ "@/components/page/admin/doctorPreview.vue")
  },
  {
    path: "/",
    categary: routeType.admin,
    name: "doctorList",
    component: () => import(/* webpackChunkName: "doctorList", webpackPrefetch: true */ "@/components/page/admin/doctorList.vue")
  },
  {
    path: "/doctorList",
    categary: routeType.admin,
    name: "doctorList",
    component: () => import(/* webpackChunkName: "doctorList", webpackPrefetch: true */ "@/components/page/admin/doctorList.vue")
  },
  {
    path: "/doctorMeets",
    categary: routeType.admin,
    name: "doctorMeets",
    component: () => import(/* webpackChunkName: "doctorMeets", webpackPrefetch: true */ "@/components/page/admin/doctorMeets.vue")
  },
  {
    path: "/createMeeting",
    categary: routeType.admin,
    name: "createMeeting",
    component: () => import(/* webpackChunkName: "createMeeting", webpackPrefetch: true */ "@/components/page/admin/meetingInfo.vue")
  },
  {
    path: "/coursePreview",
    categary: routeType.admin,
    name: "coursePreview",
    component: () => import(/* webpackChunkName: "coursePreview", webpackPrefetch: true */ "@/components/page/admin/coursePreview.vue")
  },
]
const commonRoutes = [
  {
    path: "/information",
    categary: routeType.ignore,
    name: "information",
    component: () => import(/* webpackChunkName: "information", webpackPrefetch: true */ "@/components/page/user/information.vue")
  },

  {
    path: "/guideTop10",
    categary: routeType.ignore,
    name: "guideTop10",
    component: () => import(/* webpackChunkName: "guideTop10", webpackPrefetch: true */ "@/components/page/guideTOP10/guideTop10.vue")
  },
  {
    path: '/patientShare/challenge',
    name: 'patientShareChallenge',
    component: () => import("@/components/page/patientShare/challenge")
  },
  {
    path: '/patientShare/votingList',
    name: 'patientShareVotingList',
    component: () => import("@/components/page/patientShare/votingList")
  },
  {
    path: '/patientShare/voteDetail',
    name: 'patientShareVoteDetail',
    component: () => import("@/components/page/patientShare/votingDetail")
  },
  {
    path: '/literature/challenge',
    name: 'literatureChallenge',
    component: () => import("@/components/page/literature/challenge")
  },
  {
    path: '/literature/votingList',
    name: 'literatureVotingList',
    component: () => import("@/components/page/literature/votingList")
  },
  {
    path: '/literature/voteDetail',
    name: 'literatureVoteDetail',
    component: () => import("@/components/page/literature/votingDetail")
  },
]
const doctorRoutes = [
  {
    path: "/myMeeting",
    categary: routeType.doctor,
    name: "myMeeting",
    component: () => import(/* webpackChunkName: "myMeeting", webpackPrefetch: true */ "@/components/page/doctor/myMeeting.vue")
  },
  {
    path: "/historyMeeting",
    categary: routeType.doctor,
    name: "historyMeeting",
    component: () => import(/* webpackChunkName: "historyMeeting", webpackPrefetch: true */ "@/components/page/doctor/historyMeeting.vue")
  },
  {
    path: "/meetDetail",
    categary: routeType.doctor,
    name: "meetDetail",
    component: () => import(/* webpackChunkName: "meetDetail", webpackPrefetch: true */ "@/components/page/doctor/meetDetail.vue")
  },
  {
    path: "/agreement/:id",
    categary: routeType.doctor,
    name: "agreement",
    component: () => import(/* webpackChunkName: "agreement", webpackPrefetch: true */ "@/components/page/agreement/agreement.vue")
  },

  {
    path: "/meetingInfo",
    categary: routeType.admin,
    name: "meetingInfo",
    component: () => import(/* webpackChunkName: "meetingInfo", webpackPrefetch: true */ "@/components/page/admin/meetingInfo.vue")
  },

]
const meetingRoutes = [
  {
    path: "/meeting/:id",
    categary: routeType.meeting,
    name: "meeting",
    meta: {
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "meeting", webpackPrefetch: true */ "@/components/page/meeting/meeting.vue")
  }
]
const ignoreRoutes = [
  {
    path: "/page404",
    categary: routeType.ignore,
    name: "page404",
    component: () => import(/* webpackChunkName: "page404", webpackPrefetch: true */ "@/components/common/page404.vue")
  },
  {
    path: "/jump",
    categary: routeType.ignore,
    name: "jump",
    component: () => import(/* webpackChunkName: "jump", webpackPrefetch: true */ "@/components/main/jump.vue")
  },
  {
    path: "/login",
    categary: routeType.common,
    name: "login",
    component: () => import(/* webpackChunkName: "login", webpackPrefetch: true */ "@/components/page/user/login.vue")
  },
  {
    path: "/sign",
    categary: routeType.meeting,
    name: "sign",
    component: () => import(/* webpackChunkName: "sign", webpackPrefetch: true */ "@/components/page/doctor/sign.vue")
  }
]
const routes = [].concat(commonRoutes, adminRoutes, doctorRoutes, meetingRoutes, ignoreRoutes);
// const routes =  []
const router = createRouter({
  history: createWebHashHistory(),
  routes
});
const ignorePages = ignoreRoutes.map(item => item.name) // 不需要验证的页面
const commonPages = commonRoutes.map(item => item.name)  // 通用页面,只需要openid，其他不验证
const doctorPages = doctorRoutes.map(item => item.name)  // 医生页面
const adminPages = adminRoutes.map(item => item.name) // 管理员页面
const meetingPages = meetingRoutes.map(item => item.name) // 会议界面


router.beforeEach((to, from, next) => {
  // 初始化微信对象 
  tool.isLocal() ? development(to, from, next) : produce(to, from, next);
})
async function development(to, from, next) {
  // weixin.init()
  // tool.setCookie("openid", "o22ML69HIW-hKsC2ahTvyOTHgfAw")//相菊
  tool.setCookie("openid", "o22ML6z7rg9HZeSABiHOqe_CqG-s")//杨昌权
  //tool.setCookie("openid","o22ML60fpHoC3uGeWkD6mQ4r5Lds")//相菊
  getProjectName(to, from, next)
  if (ignorePages.includes(to.name)) { next(); return; }
  // to.query.projectId = tool.getStorage("projectId",sessionStorage)
  inSystem(to, from, next)
}
async function produce(to, from, next) {
  weixin.init()
  getProjectName(to, from, next)
  if (ignorePages.includes(to.name)) { next(); return; }
  let openid = tool.getCookie("openid") || "";
  if (!openid) {
    next({ name: "jump", path: "/jump", query: { path: to.path, ...to.query } })
    return;
  }
  inSystem(to, from, next)

}
const getProjectName = (to, from, next) => {
  if (to.query.projectId) {
    tool.setStorage("projectId", to.query.projectId, sessionStorage)
  }
  let projectId = to.query.projectId || tool.getStorage("projectId", sessionStorage)
  if (projectId && !store.state.common.projectInfo.id) {
    loadProjectInfo()
  }
}
async function inSystem(to, from, next) {
  // next();
  // return;
  let name = to.name;
  // 可跳过 路由
  if (commonPages.includes(name)) { // 通用页面
    next();
    return;
  } else if (doctorPages.includes(name)) { // 医生页面
    if (to.query.other_company_id) {
      store.dispatch("setItem", { otherInfo: to.query })
    }
    isRegister(to, from, next)
  } else if (adminPages.includes(name)) { // 管理员页面
    isLogin(to, from, next)
  } else if (meetingPages.includes(name)) { // 会议界面
    isSign(to, from, next)
  } else { // 错误的路由
    next("/page404")
  }

}
async function isLogin(to, from, next) {
  let adminInfo = store.state.common.adminInfo;
  let adminLoginData = tool.getLocalCookie("adminLoginData") || {}
  if (!adminLoginData.phone) {
    next("/login")
    return;
  }
  if (!adminInfo.phone) {
    axios.post(urls.user.login, adminLoginData).then(res => {
      store.dispatch("setItem", { adminInfo: res.data })
      tool.setLocalCookie('adminLoginData', adminLoginData)
      let projectId = tool.getStorage("projectId", sessionStorage);
      if (!projectId) {
        next("/login")
      } else {
        next()
      }
    }).catch(err => {
      next("/login")
    })
    return;
  }
  tool.setLocalCookie('adminLoginData', adminLoginData)
  next();
}

async function isRegister(to, from, next) {
  let doctorInfo = store.state.common.doctorInfo;
  if (doctorInfo.openid) { next(); return; }
  let url = `${urls.doctor.doctorInfo}`
  let response = await axios.get(url)
  if (response.data && response.data.openid) {
    store.dispatch("setItem", { doctorInfo: response.data })
    next()
  } else {
    next("/information")
  }
}
async function isSign(to, from, next) {
  let signInfo = store.state.common.signInfo;
  if (signInfo.openid) { next(); return; }
  let url = tool.getURL(urls.meet.sign, tool.getSystemInfo())
  let response = await axios.get(url)
  if (response.data && response.data.openid) {
    store.dispatch("setItem", { signInfo: response.data })
    next()
  } else {
    next({
      path: "/sign",
      query: {
        meetingId: to.params.id,
        ...to.query
      }
    })
  }
}



const loadProjectInfo = () => {
  let url = tool.getURL(
    urls.admin.project,
    {
      project_id: tool.getStorage("projectId", sessionStorage)
    }
  )
  axios.get(url).then(res => {
    store.dispatch("setItem", { projectInfo: res.data })
  }).catch(err => { })
}

export default router;